import React from "react"
import * as styles from "./Systematic.module.scss"
import Container from "react-bootstrap/Container"
import bgimg from "../../images/web-application/mainimg.png"
import overlay from "../../images/web-application/overlayimg.png"
import giffbg from "../../images/web-application/giffbg.png"
import process from "../../images/web-application/up.svg"
import borderStock from "../../images/web-application/borderStroke.svg"
import mainDottedRight from "../../images/custom/dottedlineMainRight.svg"
import mainDottedLeft from "../../images/custom/dottedMainLeft.svg"
const Systematic = ({ strapiData }) => {
  const lottieImagesData = [
    {
      lottie:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/manage_9032647abb.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/optimize_ae6d6397f1.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/elaburate_1bf3b33213.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/operate_05312ad4e4.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/discover_ed9f882a6c.json",
    },
    {
      lottie:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/support_4f7b0238c2.json",
    },
  ]
  const arrowImages = [
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
    {
      arrowImg: process,
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.systemNoCodeProcess}>
        <Container>
          <h2
            className={styles.strategyHeading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.stagegyDescription}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div
            className={` align-items-center justify-content-between d-flex ${styles.processDetails}`}
          >
            <div className={`${styles.processes}`}>
              <h3 className={`${styles.processData}`}>01 - Discover</h3>
              <p className={`${styles.processDes}`}>
                We begin by gaining clarity on your basic cloud consulting
                requirements and mapping out clear cloud strategies with
                probable outcomes.
              </p>
              <h3 className={`${styles.processData2}`}>02 - Elaborate</h3>
              <p className={`${styles.processDes}`}>
                After discovering your potential needs, we start filling the
                gaps and determining which cloud strategy will suit your
                concerns the best.
              </p>
              <h3 className={`${styles.processData3}`}>03 - Optimize</h3>
              <p className={`${styles.processDes}`}>
                In case you seek to optimize resource usage in an already
                existing cloud environment or you want to cut the operating
                cost, we will do it for you.
              </p>
            </div>
            <div className={`${styles.imageGiffs}`}>
              <img src={bgimg} alt="" className={styles.bgImage} />
              <img src={overlay} alt="" className={styles.overlay} />
              <img src={giffbg} alt="" className={styles.giffBg} />
              <img src={giffbg} alt="" className={styles.giffBg2} />
              <img src={giffbg} alt="" className={styles.giffBg3} />
              <img src={giffbg} alt="" className={styles.giffBg4} />
              <img src={giffbg} alt="" className={styles.giffBg5} />
              <img src={giffbg} alt="" className={styles.giffBg6} />
              {arrowImages &&
                arrowImages?.map((a, r) => (
                  <div className={styles[`arrowPics${r + 1}`]} key={r}>
                    <img src={a.arrowImg} alt="" />
                  </div>
                ))}
              <img src={borderStock} alt="" className={styles.borderStock} />
              {lottieImagesData &&
                lottieImagesData?.map((e, i) => (
                  <div className={styles[`lottieImages${i + 1}`]} key={i}>
                    <lottie-player
                      autoplay
                      loop
                      src={e?.lottie}
                      style={{ height: "60px", width: "60px", margin: "0px" }}
                    />
                  </div>
                ))}
            </div>
            <div>
              <img
                src={mainDottedRight}
                alt=""
                className={styles.mainDottedRight1}
              />
              <img
                src={mainDottedLeft}
                alt=""
                className={styles.mainDottedLeft1}
              />
              <img
                src={mainDottedRight}
                alt=""
                className={styles.mainDottedRight2}
              />
              <img
                src={mainDottedLeft}
                alt=""
                className={styles.mainDottedLeft2}
              />
              <img
                src={mainDottedRight}
                alt=""
                className={styles.mainDottedRight3}
              />
              <img
                src={mainDottedLeft}
                alt=""
                className={styles.mainDottedLeft3}
              />
            </div>
            <div className={`${styles.rightProcess}`}>
              <h3 className={`${styles.rightProcessData}`}>06 - Support</h3>
              <p className={`${styles.rightProcessDes}`}>
                Ultimately, we closely monitor and optimize the cloud resource
                usage and cost. We do it to maximize the perks of your cloud
                infrastructure.
              </p>
              <h3 className={`${styles.rightProcessData2}`}>05 - Operate</h3>
              <p className={`${styles.rightProcessDes}`}>
                Finally, we execute what we planned at the very beginning. This
                implementation stage marks the efforts that we put into your
                project to make it a hit.
              </p>
              <h3 className={`${styles.rightProcessData3}`}>04 - Manage</h3>
              <p className={`${styles.rightProcessDes}`}>
                Meticulous testing ensures flawless functionality, identifying
                and refining issues or enhancements.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Systematic
