import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import borderBottom from "../../images/web-application/bottomimg.svg"
import * as styles from "./ConsultingServices.module.scss"

const Services = ({ strapiData, cmsIndustries, noCode }) => {
  return (
    <React.Fragment>
      <div
        className={`${styles.packedIndustryContainer} ${
          cmsIndustries ? styles.cmsIndustriesWeb : ""
        } ${noCode ? styles.nocodeIndustry : ""}`}
      >
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={` ${styles.cardWrapper} gap-30`}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col xl={3} md={6} xs={12} key={i}>
                  <div
                    className={`${styles.card} ${
                      cmsIndustries ? styles.cmsCard : ""
                    } ${noCode ? styles.noCodeCard : ""}`}
                  >
                    <Link to={e?.buttons[0]?.url}>
                      <h3>{e?.title}</h3>
                    </Link>
                    <img
                      src={e?.image1[0]?.localFile?.publicURL}
                      alt={strapiData?.title}
                      decoding="async"
                      loading="lazy"
                      className={styles.awsImgs}
                    />
                    <img
                      className={styles.cmsBottomimg}
                      src={borderBottom}
                      alt="line"
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Services
