// extracted by mini-css-extract-plugin
export var SolutionsMobileClients = "SolutionsMobile-module--SolutionsMobileClients--cdf16";
export var bottomContent = "SolutionsMobile-module--bottomContent--ac5bd";
export var bottomHead = "SolutionsMobile-module--bottomHead--82497";
export var bottomPort = "SolutionsMobile-module--bottomPort--98de9";
export var bottomPort2 = "SolutionsMobile-module--bottomPort2--86c7a";
export var bottomTags = "SolutionsMobile-module--bottomTags--10aec";
export var bottomhead1 = "SolutionsMobile-module--bottomhead1--593a5";
export var bottomtag1 = "SolutionsMobile-module--bottomtag1--3283d";
export var bottomtag2 = "SolutionsMobile-module--bottomtag2--6b5a5";
export var bottomtag3 = "SolutionsMobile-module--bottomtag3--0b501";
export var bottomtag3Hover = "SolutionsMobile-module--bottomtag3Hover--21710";
export var cardWrapper = "SolutionsMobile-module--cardWrapper--30fb3";
export var caseStudiesBtnPortfolio = "SolutionsMobile-module--caseStudiesBtnPortfolio--aa2d6";
export var description = "SolutionsMobile-module--description--3fb91";
export var firstMobileImagaPort = "SolutionsMobile-module--firstMobileImagaPort--dc7c7";
export var firstPortBottom = "SolutionsMobile-module--firstPortBottom--a5da8";
export var firstPortFolio = "SolutionsMobile-module--firstPortFolio--6bb23";
export var head = "SolutionsMobile-module--head--bd1d4";
export var heading = "SolutionsMobile-module--heading--fc67d";
export var hoverFirstPortImg = "SolutionsMobile-module--hoverFirstPortImg--1dfaa";
export var normalrFirstPortImg = "SolutionsMobile-module--normalrFirstPortImg--04e28";
export var portImage = "SolutionsMobile-module--portImage--bc8d7";
export var portfolioLinkImage = "SolutionsMobile-module--portfolioLinkImage--8ded8";
export var project2 = "SolutionsMobile-module--project2--890fd";
export var projectsAll = "SolutionsMobile-module--projectsAll--3ad4f";
export var tag1 = "SolutionsMobile-module--tag1--67139";
export var tag2 = "SolutionsMobile-module--tag2--85d74";
export var tag3 = "SolutionsMobile-module--tag3--6b885";
export var tags = "SolutionsMobile-module--tags--e072e";