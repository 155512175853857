import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./cloud.scss"
import * as styles from "./AngularTechStacks.module.scss"

const data = [
  {
    title: "Serverless",
    tech: [
      {
        name: "AWS Lambda",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_lambda_85e27c9e4f.svg",
      },
      {
        name: "Azure Functions",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_86eb3d0f63.svg",
      },
      {
        name: "Cloud Functions",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/google_cloud_functions_fdfbcadeb5.svg",
      },
      {
        name: "ECS Fargate",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ecs_fargate_8483d860e1.svg",
      },
      {
        name: "Azure Container",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_container_e4f8bae378.svg",
      },
      {
        name: "ElasticBeanstak",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/elastic_beanstak_dfece12186.svg",
      },
    ],
  },
  {
    title: "Infrastructure as a Code",
    tech: [
      {
        name: "Terraform",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/terraformio_424edcc3dd.svg",
      },
      {
        name: "AWS Cloudformation",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_cloudformation_cb54962add.svg",
      },
      {
        name: "Azure Manager",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_manager_a342cf34c5.svg",
      },
      {
        name: "Pulumi",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pulumi_8c4907dddb.svg",
      },
      {
        name: "Ansible",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ansible_9bcca6a00d.svg",
      },
    ],
  },
  {
    title: "Automation and Orchestration",
    tech: [
      {
        name: "Ansible",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ansible_9bcca6a00d.svg",
      },
      {
        name: "Puppet",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/puppet_43fbe51a41.svg",
      },
      {
        name: "Chef",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/chefio_2202cf9c40.svg",
      },
      {
        name: "Saltstack",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/saltstack_dfa27cbdce.svg",
      },
      {
        name: "Kubernetes",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kubernetes_0f9bb9599c.svg",
      },
      {
        name: "Docker Swarm",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/docker_swarm_eb063cdaac.webp",
      },
    ],
  },
  {
    title: "CI/CD",
    tech: [
      {
        name: "Jenkins",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jenkins_e23c97b44d.svg",
      },
      {
        name: "Travis CI",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/travis_ci_abb089dcfb.svg",
      },
      {
        name: "Circle CI",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/circleci_9d66ebda5d.svg",
      },
      {
        name: "GitLab CI/CD",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gitlab_a565cc0b70.svg",
      },
      {
        name: "GitHub Actions",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/github_a7101afa58.svg",
      },
      {
        name: "Team City",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/teamcity_20a4b7449a.svg",
      },
      {
        name: "Bamboo",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bamboo_b86723ae5b.svg",
      },
      {
        name: "Azure DevOps",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_devops_4f11de8b1a.svg",
      },
      {
        name: "GoCD",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gocd_a3684ddc77.svg",
      },
      {
        name: "AWS Code Pipeline",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_codepipeline_c89aa7da57.svg",
      },
      {
        name: "GitBucket",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gitbucket_2b96c566dc.svg",
      },
      {
        name: "ArgoCD",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/argo_cd_942e68fd1d.svg",
      },
      {
        name: "BitBucket",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bit_bucket_a957a55a73.svg",
      },
    ],
  },
  {
    title: "Monitoring & logging",
    tech: [
      {
        name: "Prometheus",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/prometheusio_50264a5ef1.svg",
      },
      {
        name: "Grafana",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/grafana_1258751aff.svg",
      },
      {
        name: "Elasticssearch",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/elasticsearch_4877e6521a.svg",
      },
      {
        name: "Logstash",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/logstash_29348f0c63.svg",
      },
      {
        name: "Kibana",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kibana_e0a7b4bfd5.svg",
      },
      {
        name: "Zabbix",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/zabbix_0657c47e36.svg",
      },
      {
        name: "Nagios",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nagios_edce480f2b.svg",
      },
      {
        name: "Datadog",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/datadog_3d7494b197.svg",
      },
      {
        name: "Splunk",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/splunk_81b57077c3.svg",
      },
      {
        name: "New Relic",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/new_relic_2fe0271ebe.svg",
      },
    ],
  },
]

const AngularTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="cloud">
      <div className={styles.TechStacksWebHireAngular}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContentAngular}>
              <div>
                <Nav variant="pills" className={`cloud`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockAngularWeb">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default AngularTechStacks
