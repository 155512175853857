// extracted by mini-css-extract-plugin
export var SliderWidth = "Solutions-module--SliderWidth--5b853";
export var arrowImg = "Solutions-module--arrowImg--9a69c";
export var bottomContent = "Solutions-module--bottomContent--6ffba";
export var bottomHead = "Solutions-module--bottomHead--ebfcd";
export var bottomPort = "Solutions-module--bottomPort--3d1ef";
export var bottomPort2 = "Solutions-module--bottomPort2--66d37";
export var bottomTags = "Solutions-module--bottomTags--cacae";
export var bottomhead1 = "Solutions-module--bottomhead1--bbaec";
export var bottomtag1 = "Solutions-module--bottomtag1--ffe3a";
export var bottomtag2 = "Solutions-module--bottomtag2--9bbd5";
export var bottomtag3 = "Solutions-module--bottomtag3--40cf9";
export var bottomtag3Hover = "Solutions-module--bottomtag3Hover--e346e";
export var cardWrapper = "Solutions-module--cardWrapper--6de92";
export var cards = "Solutions-module--cards--a5d2d";
export var description = "Solutions-module--description--de079";
export var heading = "Solutions-module--heading--2639c";
export var headingPremium = "Solutions-module--headingPremium--76078";
export var mainWorkSolutionsWeb = "Solutions-module--mainWorkSolutionsWeb--6a7a8";
export var portfolioArrowIcon = "Solutions-module--portfolioArrowIcon--39967";
export var portfolioArrowIconCover = "Solutions-module--portfolioArrowIconCover--d5fff";
export var portfolioArrowRightIconCover = "Solutions-module--portfolioArrowRightIconCover--0760f";
export var portfolioImg = "Solutions-module--portfolioImg--f7a20";
export var portfolioLinkImage = "Solutions-module--portfolioLinkImage--6acf3";
export var premiumImg = "Solutions-module--premiumImg--7c80d";
export var project2 = "Solutions-module--project2--1e7a0";
export var projectsAll = "Solutions-module--projectsAll--9646a";
export var providingImg = "Solutions-module--providingImg--7a7f3";
export var reactJsIconContainer = "Solutions-module--reactJsIconContainer--3ea2d";
export var reactJsIconContainerLeft = "Solutions-module--reactJsIconContainerLeft--3a7b5";
export var teamButton = "Solutions-module--teamButton--ff494";
export var trailBg = "Solutions-module--trailBg--f2037";