// extracted by mini-css-extract-plugin
export var ServicesWhyAngular = "Solutions-module--ServicesWhyAngular--51b7d";
export var card = "Solutions-module--card--33b83";
export var cardWrapper = "Solutions-module--cardWrapper--537eb";
export var description = "Solutions-module--description--807f2";
export var heading = "Solutions-module--heading--da93e";
export var loader = "Solutions-module--loader--33697";
export var lodaMoreBtn = "Solutions-module--lodaMoreBtn--a30bc";
export var lodalessBtn = "Solutions-module--lodalessBtn--140dc";
export var showlessdiv = "Solutions-module--showlessdiv--ef33d";
export var spin = "Solutions-module--spin--9af66";
export var trailBg = "Solutions-module--trailBg--ddefe";