// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "AngularTechStacks-module--TechStacksWebHireAngular--481ed";
export var cardBg = "AngularTechStacks-module--cardBg--9eec0";
export var cards = "AngularTechStacks-module--cards--d4f33";
export var cardsContentAngular = "AngularTechStacks-module--cardsContentAngular--702d2";
export var description = "AngularTechStacks-module--description--361c8";
export var heading = "AngularTechStacks-module--heading--fc2fa";
export var iconssCard = "AngularTechStacks-module--iconssCard--e17d7";
export var nav = "AngularTechStacks-module--nav--14eac";
export var navItem = "AngularTechStacks-module--nav-item--7fc18";
export var navbarBlock = "AngularTechStacks-module--navbarBlock--b6105";
export var tabData = "AngularTechStacks-module--tabData--67696";
export var tech = "AngularTechStacks-module--tech--03c34";
export var techIcon = "AngularTechStacks-module--techIcon--3f8e9";
export var techImg = "AngularTechStacks-module--techImg--0afee";
export var technologyIcon = "AngularTechStacks-module--technologyIcon--33055";