import { Script, graphql } from "gatsby"
import React, { useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/cloud-servce/Banner"
import WhyHireAngular from "../components/cloud-servce/WhyHireAngular"
import AngularTechStacks from "../components/cloud-servce/AngularTechStacks"
import AngularTechStacksMobile from "../components/cloud-servce/AngularTechStacksMobile"
import Solutions from "../components/hire-python-developers/Solutions"
import SolutionsMobile from "../components/hire-python-developers/SolutionsMobile"
import Success from "../components/web-application-development-in-usa/Success"
import Finest from "../components/cloud-servce/Finest"
import Handle from "../components/cloud-servce/Handle"
import Services from "../components/cloud-servce/Services"
import Experience from "../components/cloud-servce/Experience"
import Platform from "../components/cloud-servce/Solutions"
import ConsultingServices from "../components/cloud-servce/ConsultingServices"
import Systematic from "../components/no-code/Systematic"
import SystematicMobile from "../components/no-code/SystematicMobile"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const cloudServices = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const heroSection = data?.strapiPage?.sections[0]
  const whyhire = data?.strapiPage?.sections[1]
  const cta = data?.strapiPage?.sections[2]
  const consultingServices = data?.strapiPage?.sections[3]
  const TechStacksAngular = data?.strapiPage.sections[4]
  const handle = data?.strapiPage?.sections[5]
  const platform = data?.strapiPage?.sections[6]
  const consulting = data?.strapiPage?.sections[7]
  const services = data?.strapiPage?.sections[8]
  const experience = data?.strapiPage?.sections[9]
  const awesome = data?.strapiPage?.sections[10]
  const solutions = data?.strapiPage?.sections[11]
  const faqs = data?.strapiPage?.sections[12]

  return (
    <MainLayout>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        pageName="Hire Angular Developers"
      />
      <WhyHireAngular strapiData={whyhire} />
      <Finest strapiData={cta} />
      <ConsultingServices strapiData={consultingServices} />
      {!isMobile && !isTablet ? (
        <AngularTechStacks strapiData={TechStacksAngular} />
      ) : (
        <AngularTechStacksMobile strapiData={TechStacksAngular} />
      )}
      <Handle strapiData={handle} />
      <Platform strapiData={platform} />
      {!isMobile && !isTablet ? (
        <Systematic strapiData={consulting} />
      ) : (
        <SystematicMobile strapiData={consulting} />
      )}
      <Services strapiData={services} />
      <Experience strapiData={experience} />
      <Success strapiData={awesome} />
      {!isMobile && !isTablet ? (
        <Solutions strapiData={solutions} />
      ) : (
        <SolutionsMobile strapiData={solutions} />
      )}
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "cloud-consulting-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default cloudServices
