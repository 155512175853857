// extracted by mini-css-extract-plugin
export var SliderWidth = "Success-module--SliderWidth--6c452";
export var SuccessWebUSA = "Success-module--SuccessWebUSA--78ee4";
export var arrowImg = "Success-module--arrowImg--23c88";
export var bottomOfCard = "Success-module--bottomOfCard--97fb0";
export var card = "Success-module--card--c15ce";
export var cardWrapper = "Success-module--cardWrapper--f9138";
export var cards = "Success-module--cards--a7bec";
export var clientData = "Success-module--clientData--55424";
export var clientDec = "Success-module--clientDec--21908";
export var clientImg = "Success-module--clientImg--939a7";
export var clientName = "Success-module--clientName--99c3a";
export var clientRating = "Success-module--clientRating--af7c6";
export var clientsData = "Success-module--clientsData--59575";
export var description = "Success-module--description--2ad64";
export var heading = "Success-module--heading--3b0ad";
export var headingPremium = "Success-module--headingPremium--1ece3";
export var iconContainer = "Success-module--iconContainer--e98f8";
export var iconContainerLeft = "Success-module--iconContainerLeft--03b1a";
export var innerCardReview = "Success-module--innerCardReview--c0128";
export var portfolioArrowIcon = "Success-module--portfolioArrowIcon--9fbc0";
export var portfolioArrowIconCover = "Success-module--portfolioArrowIconCover--20ab4";
export var portfolioArrowRightIconCover = "Success-module--portfolioArrowRightIconCover--46f54";
export var providingImg = "Success-module--providingImg--791d2";
export var trailBg = "Success-module--trailBg--29743";