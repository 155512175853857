// extracted by mini-css-extract-plugin
export var SliderWidth = "ConsultingServices-module--SliderWidth--2aefc";
export var arrowImg = "ConsultingServices-module--arrowImg--97236";
export var awsImgs = "ConsultingServices-module--awsImgs--c352f";
export var card = "ConsultingServices-module--card--85213";
export var cardWrapper = "ConsultingServices-module--cardWrapper--47e2a";
export var cards = "ConsultingServices-module--cards--f5711";
export var cmsBottomimg = "ConsultingServices-module--cmsBottomimg--93739";
export var cmsCard = "ConsultingServices-module--cmsCard--7c3e2";
export var cmsIndustriesWeb = "ConsultingServices-module--cmsIndustriesWeb--f5855";
export var description = "ConsultingServices-module--description--52328";
export var heading = "ConsultingServices-module--heading--eee8f";
export var headingPremium = "ConsultingServices-module--headingPremium--e82a7";
export var iconContainer = "ConsultingServices-module--iconContainer--ad7e4";
export var iconContainerLeft = "ConsultingServices-module--iconContainerLeft--4da46";
export var noCodeCard = "ConsultingServices-module--noCodeCard--8f6d4";
export var nocodeIndustry = "ConsultingServices-module--nocodeIndustry--16b7a";
export var packedIndustryContainer = "ConsultingServices-module--packedIndustryContainer--cf579";
export var portfolioArrowIcon = "ConsultingServices-module--portfolioArrowIcon--a0491";
export var portfolioArrowIconCover = "ConsultingServices-module--portfolioArrowIconCover--7d3c7";
export var portfolioArrowRightIconCover = "ConsultingServices-module--portfolioArrowRightIconCover--46e22";
export var providingImg = "ConsultingServices-module--providingImg--9382c";
export var trailBg = "ConsultingServices-module--trailBg--b5c4b";