// extracted by mini-css-extract-plugin
export var ServicesWhyAngular = "Handle-module--ServicesWhyAngular--5b574";
export var card = "Handle-module--card--95e1b";
export var cardWrapper = "Handle-module--cardWrapper--57ba6";
export var description = "Handle-module--description--7547a";
export var heading = "Handle-module--heading--b08a0";
export var loader = "Handle-module--loader--5d877";
export var lodaMoreBtn = "Handle-module--lodaMoreBtn--32669";
export var lodalessBtn = "Handle-module--lodalessBtn--cef6d";
export var showlessdiv = "Handle-module--showlessdiv--9bd5f";
export var spin = "Handle-module--spin--d093f";
export var trailBg = "Handle-module--trailBg--0ffae";