// extracted by mini-css-extract-plugin
export var cards = "Services-module--cards--10671";
export var description = "Services-module--description--0e14b";
export var hireImg = "Services-module--hireImg--d912d";
export var hireTechnicalSetsConsulting = "Services-module--hireTechnicalSetsConsulting--1683f";
export var nocodeWeb = "Services-module--nocodeWeb--6162f";
export var subTitle = "Services-module--subTitle--edbce";
export var talent = "Services-module--talent--55149";
export var text = "Services-module--text--39a4e";
export var tick = "Services-module--tick--51339";
export var whyChooseConsultingWeb = "Services-module--whyChooseConsultingWeb--ab215";