// extracted by mini-css-extract-plugin
export var ServicesWhyAngular = "WhyHireAngular-module--ServicesWhyAngular--13232";
export var card = "WhyHireAngular-module--card--1601c";
export var cardWrapper = "WhyHireAngular-module--cardWrapper--dff2c";
export var description = "WhyHireAngular-module--description--db12f";
export var heading = "WhyHireAngular-module--heading--2ea6e";
export var loader = "WhyHireAngular-module--loader--8b5a7";
export var lodaMoreBtn = "WhyHireAngular-module--lodaMoreBtn--3d1b7";
export var lodalessBtn = "WhyHireAngular-module--lodalessBtn--8845b";
export var showlessdiv = "WhyHireAngular-module--showlessdiv--98252";
export var spin = "WhyHireAngular-module--spin--212f4";
export var trailBg = "WhyHireAngular-module--trailBg--7ee2f";