// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--367c9";
export var cardBg = "AngularTechStacksMobile-module--cardBg--4660b";
export var cards = "AngularTechStacksMobile-module--cards--f504d";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--eb0ca";
export var description = "AngularTechStacksMobile-module--description--f2c91";
export var heading = "AngularTechStacksMobile-module--heading--aa02a";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--609aa";
export var nav = "AngularTechStacksMobile-module--nav--21583";
export var navItem = "AngularTechStacksMobile-module--nav-item--2afc5";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--5d98e";
export var tabData = "AngularTechStacksMobile-module--tabData--f8dfb";
export var tech = "AngularTechStacksMobile-module--tech--4bb38";
export var techIcon = "AngularTechStacksMobile-module--techIcon--fa71d";
export var techImg = "AngularTechStacksMobile-module--techImg--2a687";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--a03c3";