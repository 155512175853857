// extracted by mini-css-extract-plugin
export var arrowPics1 = "Systematic-module--arrowPics1--9dc3e";
export var arrowPics2 = "Systematic-module--arrowPics2--e1ad1";
export var arrowPics3 = "Systematic-module--arrowPics3--228a5";
export var arrowPics4 = "Systematic-module--arrowPics4--3a35d";
export var arrowPics5 = "Systematic-module--arrowPics5--ebfca";
export var bgImage = "Systematic-module--bgImage--bc024";
export var borderStock = "Systematic-module--borderStock--e3224";
export var dottedLine1 = "Systematic-module--dottedLine1--c2712";
export var giffBg = "Systematic-module--giffBg--58bcd";
export var giffBg2 = "Systematic-module--giffBg2--bff5d";
export var giffBg3 = "Systematic-module--giffBg3--27d5a";
export var giffBg4 = "Systematic-module--giffBg4--1dba4";
export var giffBg5 = "Systematic-module--giffBg5--8ac27";
export var giffBg6 = "Systematic-module--giffBg6--9f68f";
export var imageGiffs = "Systematic-module--imageGiffs--1eb90";
export var lottieImages1 = "Systematic-module--lottieImages1--e07ee";
export var lottieImages2 = "Systematic-module--lottieImages2--42ee6";
export var lottieImages3 = "Systematic-module--lottieImages3--8f8a8";
export var lottieImages4 = "Systematic-module--lottieImages4--efac2";
export var lottieImages5 = "Systematic-module--lottieImages5--fc0f5";
export var lottieImages6 = "Systematic-module--lottieImages6--87374";
export var mainDottedLeft1 = "Systematic-module--mainDottedLeft1--640b9";
export var mainDottedLeft2 = "Systematic-module--mainDottedLeft2--a1520";
export var mainDottedLeft3 = "Systematic-module--mainDottedLeft3--c18cc";
export var mainDottedRight1 = "Systematic-module--mainDottedRight1--1cbe2";
export var mainDottedRight2 = "Systematic-module--mainDottedRight2--cbbf3";
export var mainDottedRight3 = "Systematic-module--mainDottedRight3--6853f";
export var overlay = "Systematic-module--overlay--1a98b";
export var processData = "Systematic-module--processData--cf5ac";
export var processData2 = "Systematic-module--processData2--24cb9";
export var processData3 = "Systematic-module--processData3--e8fce";
export var processDes = "Systematic-module--processDes--9a942";
export var processDetails = "Systematic-module--processDetails--6a49d";
export var processes = "Systematic-module--processes--0a9dc";
export var rightProcess = "Systematic-module--rightProcess--8604a";
export var rightProcessData = "Systematic-module--rightProcessData--0c2a9";
export var rightProcessData2 = "Systematic-module--rightProcessData2--b69c0";
export var rightProcessData3 = "Systematic-module--rightProcessData3--7db92";
export var rightProcessDes = "Systematic-module--rightProcessDes--f7d14";
export var stagegyDescription = "Systematic-module--stagegyDescription--29aee";
export var strategyHeading = "Systematic-module--strategyHeading--30fde";
export var systemNoCodeProcess = "Systematic-module--systemNoCodeProcess--e4e10";